/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 115,
    });
}

/***
 * GERAL
 ***/
$(function() {

    // menu
    var $win = $(window),
        $menu = $('header nav'),
        $menuToggle = $('header .menuToggle');

    if ($win.width() <= 768) {
        $($menuToggle).click(function(event) {
            event.preventDefault();
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });

        $('header nav a').click(function(event) {
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });
    }

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'block');
        }
    });

    // imagesLoaded
    $('body').imagesLoaded().always( function( instance ) {
        $('body').addClass('loaded');
    });

    // wow
    new WOW({
        offset: 300
    }).init();

    // owl carousel
    $('.Depoimentos .owl-carousel').owlCarousel({
        items           : 1,
        autoplayTimeout : 10000,
        loop            : true,
        autoplay        : true,
        nav             : true,
        navText         : ['<i class="icon-seta_esq"></i>','<i class="icon-seta_dir"></i>'],
    });

    // owl carousel
    $('.owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        nav             : true,
        navText         : ['<i class="icon-seta_esq"></i>','<i class="icon-seta_dir"></i>'],
    });

    // Galeria
    $('.Galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            },
            image       : {
                titleSrc: function(item) {
                    var comp = item.el.find('.legenda');
                    var legenda = comp.children('span').html();
                    if (typeof legenda !== 'undefined') {
                        return '<div class="legenda"><span>'+legenda+'</span>';
                    } else {
                        return '<div class="legenda _hide"><span>'+legenda+'</span>';
                    }
                }
            }
        });
    });

    // Galeria
    $('.galeria').each(function() {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled:true
            },
            image       : {
                titleSrc: function(item) {
                    var comp = item.el.find('.legenda');
                    var legenda = comp.children('span').html();

                    console.log(comp);
                
                    if (typeof legenda !== 'undefined') {
                        return '<div class="legenda"><span>'+legenda+'</span>';
                    } else {
                        return '<div class="legenda _hide"><span>'+legenda+'</span>';
                    }
                }
            }
        });
    });

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > 20) {
            $('header').addClass('_fixed');
        } else {
            $('header').removeClass('_fixed');
        }
    });

    //SEARCH
    $('.seach_input').focus(function() {
        $('.search_select').prop('disabled', 'disabled');
        $('.search_select').val('disabled');
    });

    $('.seach_input').blur(function() {
        $('.search_select').prop('disabled', false);
    });

    $('.search_select').focus(function() {
        $('.seach_input').prop('disabled', 'disabled');
        $('.seach_input').val('');
    });

    $('.search_select').blur(function() {
        $('.seach_input').prop('disabled', false);
    });

});